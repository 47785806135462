import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import * as customerActions from '~/containers/Customer/actions';

const defaultState = {};

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.customersById]: (state, action) => {
        return action.payload;
    },

    'LOAD_CUSTOMERS_SUCCESS': (state, action) => {
        return action.payload.data.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});
    },

    'REGISTER_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.payload.id]: action.payload
        }
    },

    'DELETE_CUSTOMER_SUCCESS': (state, action) => {
        const newState = {...state};
        delete newState[action.payload.id];
        return newState;
    },

    'SAVE_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.payload.id]: action.payload
        }
    },

    'REGISTER_DASHTASK_ITEM_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.payload.data.data.customer_id]: {
                ...state[action.payload.data.data.customer_id],
                custom_fields: [
                    ...state[action.payload.data.data.customer_id].custom_fields,
                    action.payload.data.data
                ]
            }
        }
    },

    'UPDATE_DASHTASK_ITEM_SUCCESS': (state, action) => {
        return {
            ...state,
            [action.payload.data.data.customer_id]: {
                ...state[action.payload.data.data.customer_id],
                custom_fields: state[action.payload.data.data.customer_id].custom_fields.map(item => item.id !== action.payload.data.data.id ? item : action.payload.data.data)
            }
        }
    }
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_CUSTOMERS_SUCCESS',
        'REGISTER_CUSTOMER_SUCCESS',
        'DELETE_CUSTOMER_SUCCESS',
        'SAVE_CUSTOMER_SUCCESS',
        'REGISTER_DASHTASK_ITEM_SUCCESS',
        'UPDATE_DASHTASK_ITEM_SUCCESS',
        customerActions.UPDATE_PARTNER.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.customersById,
            data: newState
        });
    }

    return newState;
}

export default reducer;