import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'moment/locale/pt-br';
import get from 'lodash/get';

import {
  Button,
  Form,
  Drawer,
  Modal,
  Tabs,
} from 'antd';

import { Cod } from '~/components/UI';
import * as actions from '../actions';
import Details from './Details';
import Cadences from './Cadences';

const ProspectingDrawer = ({
  form,
}) => {
  const dispatch = useDispatch();

  const {
    drawer,
    columns,
  } = useSelector(state => state.socialSelling);
  const data = drawer.data;

  const [loading, setLoading] = useState(false);

  const handleCreate = () => {
    const { data } = drawer;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      const { name, column_id, ...restValues } = values;
      dispatch(actions.create({
        additional_data: {
          ...get(data, 'additional_data', {}),
          ...restValues,
        },
        name: values.name,
        entity_type: 'socialSelling',
        column_id: values.column_id || 1,
        board_id: 1,
      }))
      .then(() => {
        setLoading(false);
      });
  })};

  const handleSave = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      const { name, column_id, ...restValues } = values;
      dispatch(actions.update(get(data, 'id'), {
        additional_data: {
          ...get(data, 'additional_data', {}),
          ...restValues,
        },
        name: values.name,
        column_id: values.column_id || 1,
      }))
      .then(() => {
        setLoading(false);
      });
    });
  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Você realmente deseja excluir este lead?',
      content: 'Esta ação não poderá ser revertida.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => dispatch(actions.remove(data))
    });
  }

  const closeDrawerLeads = () => {
    form.resetFields();
    dispatch(actions.close());
  }

  return (
    <Drawer
      title={get(data, 'id') ? <span>Editar Lead <Cod float="right">#CRM{get(data, 'id')}</Cod></span> : 'Cadastrar Lead'}
      width={950}
      placement="right"
      onClose={closeDrawerLeads}
      maskClosable={false}
      visible={get(drawer, 'show', false)}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 53,
      }}>
      <Tabs defaultActiveKey="1" type='card'>
        <Tabs.TabPane tab="Detalhes" key="1">
          <Details 
            data={data}
            columns={columns}
            form={form}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cadência" key="2">
          <Cadences 
            data={data}
            form={form}
          />
        </Tabs.TabPane>
      </Tabs>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}
      >
        {get(data, 'id') && (<Button onClick={handleDelete} type="danger" style={{ marginRight: '8px' }}>Deletar</Button>)}
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={closeDrawerLeads}
        >
          Cancelar
        </Button>
        {get(data, 'id') ? (
          <Button onClick={handleSave} type="primary" loading={loading}>Salvar</Button>
        ) : (
          <Button onClick={handleCreate} type="primary" loading={loading}>Cadastrar</Button>
        )}
      </div>
    </Drawer>
  )
}

export default Form.create()(ProspectingDrawer);