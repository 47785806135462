import React, { useState } from "react";
import { Button, Dropdown, Icon, Menu, Modal, Table, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";

import { AppUserContainer } from "../styles";
import { UserModal } from "./UserModal";
import { CustomerPasswordModal } from "~/components/Customers/Modals/CustomerPasswordModal";
import * as customersActions from "~/containers/Customer/actions";
import { UserInviteModal } from "./UserInviteModal";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export const AppUsers = ({ customer }) => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState(customer.app_users || []);
  const [userModal, setUserModal] = useState({
    isVisible: false,
    data: null,
  });
  const [userPasswordModal, setUserPasswordModal] = useState({
    isVisible: false,
    data: null,
  });
  const [userInviteModal, setUserInviteModal] = useState({
    isVisible: false,
  });

  function showUserModal(data) {
    setUserModal({
      isVisible: true,
      data,
    });
  }

  function hideUserModal() {
    setUserModal({
      isVisible: false,
      data: null,
    });
  }

  function showUserPasswordModal(data) {
    setUserPasswordModal({
      isVisible: true,
      data,
    });
  }

  function hideUserPasswordModal() {
    setUserPasswordModal({
      isVisible: false,
      data: null,
    });
  }

  function deleteAppUser(params) {
    Modal.confirm({
      title: "Você realmente deseja remover este usuário?",
      content: "Esta ação não poderá ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: async () => {
        await deleteAppUserRequest(params);
        setUsers(users.filter((user) => user.id !== params.appUserId));
      },
    });
  }

  async function deleteAppUserRequest(params) {
    dispatch(customersActions.deleteAppUser(params));
  }
  
  function showUserInviteModal() {
    setUserInviteModal({
      isVisible: true,
    });
  }

  function hideUserInviteModal() {
    setUserInviteModal({
      isVisible: false,
    });
  }

  return (
    <AppUserContainer>
      <Button type="primary" className="mb-20" onClick={showUserInviteModal}>
        Convidar usuário
      </Button>
      <Table
        dataSource={users}
        bordered
        size="small"
        rowKey="id"
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.customersAppUsers
        })}
      >
        <Column title="Nome" key="name" dataIndex="name" />
        <Column title="E-mail" key="email" dataIndex="email" />
        <Column
          title="Último acesso"
          key="last_access_at"
          dataIndex="last_access_at"
          render={(lastAccessAt) => {
            return lastAccessAt ? (
              <Tooltip title={moment(lastAccessAt).format("DD/MM/YYYY LTS")}>
                {moment(lastAccessAt).fromNow()}
              </Tooltip>
            ) : null;
          }}
        />
        <Column
          align="center"
          key="actions"
          width={80}
          render={(_, appUser) => (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={() => showUserModal(appUser)}>
                    <Icon type="edit" /> Editar
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => showUserPasswordModal(appUser)}
                  >
                    <Icon type="lock" /> Gerar nova senha
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    onClick={() => {
                      deleteAppUser({
                        customerId: customer.id,
                        appUserId: appUser.id,
                      });
                    }}
                  >
                    <Icon type="delete" /> Excluir
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button type="link">
                Ações <Icon type="down" />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
      <UserModal
        isVisible={userModal.isVisible}
        data={userModal.data}
        hideModal={hideUserModal}
        setUsers={setUsers}
        customer={customer}
        users={users}
      />
      <UserInviteModal
        isVisible={userInviteModal.isVisible}
        hideModal={hideUserInviteModal}
        setUsers={setUsers}
        customer={customer}
      />
      <CustomerPasswordModal
        isOpen={userPasswordModal.isVisible}
        onClose={hideUserPasswordModal}
        appUser={userPasswordModal.data}
        customer={customer}
      />
    </AppUserContainer>
  );
};
