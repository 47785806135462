import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Select, Button, Icon, Modal, Form } from 'antd';
import Cadence from './Cadence';
import styled from 'styled-components';
import get from 'lodash/get';
import moment from 'moment';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  .ant-tabs {
    background-color: rgb(255, 255, 255) !important;
    padding: 20px !important;
`;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

const Cadences = ({ data, form }) => {
  const allCadences = useSelector(state => state.settings['prospecting.cadence']);
  const [cadences, setCadences] = useState([]);
  const [selectedCadence, setSelectedCadence] = useState(null);
  const [activeKey, setActiveKey] = useState([]);

  const generateCadenceDates = (cadence) => {
    const cadenceData = JSON.parse(cadence.value);
    const cadenceDates = [];
    const startDate = moment();
    cadenceData.data.map((row, index) => {
      let date = startDate.clone().add(row.day, 'days');
      // Adjust date if it falls on a weekend
      if (date.day() === 6) { // Saturday
        date.add(2, 'days');
      } else if (date.day() === 0) { // Sunday
        date.add(1, 'day');
      }
      cadenceDates.push({
        date: date.format('DD/MM/YYYY'),
        status: 'pending',
        ...row,
      });
    });
    return {
      ...cadenceData,
      data: cadenceDates,
    };
  };

  const handleAddCadence = () => {
    if (selectedCadence) {
      const selectedCadenceData = allCadences.find(cadence => cadence.id === selectedCadence);
      setCadences([...cadences, generateCadenceDates(selectedCadenceData)]);
      setSelectedCadence(null);
    }
  };

  const handleDeleteCadence = (index) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk: () => {
        setCadences(cadences.filter((_, idx) => idx !== index));
      },
    });
  };

  const renderCadenceSelect = () => (
    <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '8px' }}>Incluir cadência:</span>
      <Select
        placeholder="Selecione uma cadência"
        value={selectedCadence}
        onChange={setSelectedCadence}
        style={{ width: '60%', maxWidth: '280px', marginRight: '8px' }}
      >
        {allCadences.map(cadence => (
          <Select.Option key={cadence.id} value={cadence.id}>{JSON.parse(cadence.value).name}</Select.Option>
        ))}
      </Select>
      <Button onClick={handleAddCadence} type="primary">Iniciar</Button>
    </div>
  );

  const getNextPendingTaskDate = (cadences) => {
    const cadencesCount = cadences.length;
    const latestCadence = cadences[cadencesCount - 1];
    if (latestCadence) {
      const pendingTasks = latestCadence.data.filter(task => task.status === 'pending');
      if (pendingTasks.length > 0) {
        return pendingTasks[0];
      }
    }
    return undefined;
  };

  useEffect(() => {
    if(get(data, 'additional_data.cadences')){
      setCadences(get(data, 'additional_data.cadences', []));
    }else{
      const cadences = [];
      const initial = get(data, 'additional_data.cadence.initial', false);
      const closing = get(data, 'additional_data.cadence.closing', false);
     
      if(initial){
        cadences.push({name: 'Cadência Inicial', data: initial});
      }
      if(closing){
        cadences.push({name: 'Cadência de Fechamento', data: closing});
      }
      setCadences(cadences);
    }
  }, [data]);

  useEffect(() => {
    if (form) {
      console.log('update cadences form', cadences);
      form.setFieldsValue({ 'cadences': cadences });
      form.setFieldsValue({ 'nextPendingTask':  getNextPendingTaskDate(cadences) });
    }
    if (cadences.length > 0) {
      setActiveKey([`${cadences.length - 1}`]);
    }
  }, [cadences]);

  return (
    <>
      {form && (
        <>
          <Form.Item style={{ display: 'none' }}>
            {form.getFieldDecorator('cadences', {
              initialValue: cadences,
            })(<input type="hidden" />)}
          </Form.Item>
          <Form.Item style={{ display: 'none' }}>
            {form.getFieldDecorator('nextPendingTask')(<input type="hidden" />)}
          </Form.Item>
        </>
      )}
      {cadences.length > 0 && (
        <StyledCollapse bordered={false} activeKey={activeKey} onChange={setActiveKey}>
          {cadences.map((cadence, index) => (
            <Panel 
              header={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>{cadence.name ? cadence.name : `Cadência ${index + 1}`}</span>
                  <Icon type="delete" onClick={() => handleDeleteCadence(index)} />
                </div>
              } 
              key={index} 
              style={customPanelStyle}
            >
              <Cadence 
                data={data}
                cadenceData={cadence}
                setcadenceData={(newData) => {
                  setCadences(cadences.map((cadence, idx) => idx === index ? newData : cadence));
                }}
                type={`cadence_${index}`}
              />
            </Panel>
          ))}
        </StyledCollapse>
      )}
      {renderCadenceSelect()}
    </>
  );
};

export default Form.create()(Cadences);