import React from 'react';
import { get } from 'lodash';
import styled from "styled-components";
import moment from "moment";

const CalendarWrapper = styled.div`
    width: 66.67px;
    height: 80px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CalendarHeader = styled.div`
    width: 100%;
    height: 20px;
    background: #f05454;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    border-bottom: 1px solid #ccc;
    color: white;
    font-weight: bold;
    font-size: 10.67px; 
`;

const CalendarBody = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
`;

const DateBox = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.isLate ? '#f05454' : props.data.status === 'done' ? '#4caf50' : '#2196f3'};
`;

const Weekday = styled.div`
    font-size: 8.33px; 
    color: #555;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Calendar = ({ data }) => {
    const date = data.date;
    const day = moment(date, 'DD/MM/YYYY').format("DD");
    const weekday = moment(date, 'DD/MM/YYYY').format("dddd");
    const month = moment(date, 'DD/MM/YYYY').format("MMMM");

    const isLate = moment(date, 'DD/MM/YYYY').isBefore(moment(), 'day');

    return (
        <CalendarWrapper>
            <CalendarHeader>
                <span>{month}</span>
            </CalendarHeader>
            <CalendarBody>
                <DateBox isLate={isLate} data={data}>{day}</DateBox>
                <Weekday>{weekday}</Weekday>
            </CalendarBody>
        </CalendarWrapper>
    );
};

export function LeadCalendarIcon({ item: lead }) {
  const firstPending = get(lead, 'additional_data.nextPendingTask', null);

  if (!firstPending) return null;

  return (
    <Container>
      <Calendar data={firstPending} />
    </Container>
  );
}
