import { Col, Form, Tabs } from "antd";
import React, { useState } from "react";
import Tip from '~/components/Tip';
import ActiveProspecting from './ActiveProspecting';
import Origens from './Origens';
import SocialSelling from './SocialSelling';

const SystemVariables = () => {
  const [selectedTab, setSelectedTab] = useState("origins");

  return (
    <Form layout="horizontal">
      <Col offset={4}>
        <Tabs
          type="card"
          activeKey={selectedTab}
          onChange={(key) => setSelectedTab(key)}
        >
          <Tabs.TabPane tab={<span>Origens <Tip>Campo utilizado no módulo leads, representa a origem do lead.</Tip></span>} key="origins">
            <Origens />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>Prospecção Ativa {`>`} Cadências <Tip>Gerencie suas cadências.</Tip></span>} key="activeProspecting">
            <ActiveProspecting />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>Social Selling {`>`} Cadências <Tip>Gerencie suas cadências.</Tip></span>} key="socialSelling">
            <SocialSelling />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Form>
  );
};

export default SystemVariables;