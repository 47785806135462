import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_SOCIAL_SELLING/SHOW';
export const CLOSE = '@@DRAWER_SOCIAL_SELLING/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_SOCIAL_SELLING/CREATE');
export const BATCH = createAsyncActions('@@DRAWER_SOCIAL_SELLING/BATCH');
export const SAVE = createAsyncActions('@@DRAWER_SOCIAL_SELLING/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_SOCIAL_SELLING/DELETE');

export const FETCHQTDD = createAsyncActions('@@SOCIAL_SELLING/FETCHQTDD');
export const MOVE = createAsyncActions('@@SOCIAL_SELLING/MOVE');
export const LOAD = createAsyncActions('@@SOCIAL_SELLING/LOAD');
export const LOAD_MORE_LEADS = createAsyncActions('@@SOCIAL_SELLING/LOAD_MORE_LEADS');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/leads_v2',
            data,
            showLoading: false
        }
      }
});

export const batch = (data) => ({
    type: BATCH.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/leads_v2/batch',
            data,
            showLoading: false
        }
      }
});

export const update = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/leads_v2/${id}`,
            data,
            showLoading: false
        }
    }
});

export const remove = (lead) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/leads_v2/${lead.id}`
        },
        lead
    }
});

export const loadLeads = ({ ...params }) => ({
    type: LOAD.INIT,
    payload: {
        request: {
            method: "GET",
            url: "/leads_v2?entity_type=socialSelling",
            ...params
        }
    }
});

export const loadMoreLeads = ({ columnId, currentCount, moreCount }) => ({
    type: LOAD_MORE_LEADS.INIT,
    payload: {
        request: {
            method: "GET",
            url: `/leads_v2/more?stage=${columnId}&current_count=${currentCount}&more_count=${moreCount}&entity_type=socialSelling`,
            columnId
        },
    },
})

export const fetchQtddByMonth = ({ date }) => ({
    type: FETCHQTDD.INIT,
    payload: {
        request:{
            method: 'get',
            url:`/leads_v2/qtdd/${date}?entity_type=socialSelling`
        }
    }
});

export const move = (props) => ({
    type: MOVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/leads_v2/${props[4]}/move?entity_type=socialSelling`,
            data: {to: props[3].droppableId}
        },
    },
    props: props
});