import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Modal, Form, Input, Row, Col } from 'antd';
import * as settingActions from "~/containers/Settings/actions";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import Edit from './Edit';

const { Column } = Table;

const Origens = () => {
  const settingsAcquisition = useSelector((state) => state.settings["leads.acquisition"]);
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState({ visible: false, record: null });
  const [createModal, setCreateModal] = useState({ visible: false });
  const [deletingId, setDeletingId] = useState(null);

  const handleEdit = (record) => {
    setEditModal({ visible: true, record });
  };

  const handleSave = (value, merge) => {
    if (merge) {
      dispatch(settingActions.mergeSetting({ data: { key: "leads.acquisition", value }, id: editModal.record.id }));
    } else {
      dispatch(settingActions.updateSetting({ data: { key: "leads.acquisition", value }, id: editModal.record.id }));
    }
    setEditModal({ visible: false, record: null });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Você realmente deseja remover esta opção?",
      content: "Esta ação não poderá ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => {
        setDeletingId(record.id);
        dispatch(settingActions.deleteSetting({ id: record.id })).finally(() => {
          setDeletingId(null);
        });
      },
    });
  };

  const handleCreate = (value) => {
    dispatch(settingActions.createSetting({ data: { key: "leads.acquisition", value } }));
    setCreateModal({ visible: false });
  };

  const existingNames = settingsAcquisition.map(item => item.value);

  return (
    <>
      <Row type="flex" justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Button
            type="primary"
            onClick={() => setCreateModal({ visible: true })}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="id"
        dataSource={settingsAcquisition}
        bordered
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.systemVariablesConfig,
          defaultPageSize: 20
        })}
      >
        <Column
          key="value"
          dataIndex="value"
          title="Nome"
          sorter={(a, b) => {
            if (!a.value || !b.value) return 0;
            return a.value.localeCompare(b.value);
          }}
          defaultSortOrder="ascend"
        />
        <Column
          key="actions"
          width={80}
          align="center"
          render={(_, record) => (
            <div style={{ display: "flex", gap: 6 }}>
              <Button
                type="primary"
                icon="edit"
                onClick={() => handleEdit(record)}
              />
              <Button
                type="danger"
                icon="delete"
                loading={deletingId === record.id}
                onClick={() => handleDelete(record)}
              />
            </div>
          )}
        />
      </Table>
      <Modal
        title="Adicionar nova origem"
        visible={createModal.visible}
        onCancel={() => setCreateModal({ visible: false })}
        onOk={() => {
          const value = document.getElementById('new-origin-input').value;
          handleCreate(value);
        }}
      >
        <Form layout="vertical">
          <Form.Item
            label="Nome"
          >
            <Input id="new-origin-input" placeholder="Nome da nova origem" />
          </Form.Item>
        </Form>
      </Modal>
      <Edit
        visible={editModal.visible}
        onClose={() => setEditModal({ visible: false, record: null })}
        onSave={handleSave}
        initialValue={editModal.record ? editModal.record.value : ''}
        existingNames={existingNames}
      />
    </>
  );
};

export default Origens;