import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Layout } from '~/components/UI';
import Drawer from "./Drawer";
import * as actions from "./actions";
import { Header } from "./Header";
import { LeadColumns } from "./LeadColumns";
import { LeadsTable } from "./LeadsTable";

moment.updateLocale("pt-br", {
  weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
});

const Leads = () => {
  const dispatch = useDispatch();

  const LEAD_ENTITY = 'inss_obra';

  const columns = useSelector((state) => state.leadsv2.columns);
  const isLoading = useSelector((state) => state.leadsv2.isLoading);
  const user = useSelector((state) => state.user);

  const showDrawer = (data) => dispatch(actions.show(data));
  const loadMoreLeads = ({ columnId, currentCount, moreCount }) => {
    dispatch(actions.loadMoreLeads(LEAD_ENTITY, { columnId, currentCount, moreCount }));
  };
  const moveLead = (...props) => dispatch(actions.move(LEAD_ENTITY, props));

  const [displayMode, setDisplayMode] = useState("funnel");
  const [date, setDate] = useState(moment().format("MM-YYYY"));

  useEffect(() => {
    dispatch(actions.loadLeads(LEAD_ENTITY));
  }, []);

  return (
    <Layout>
      <Header
        date={date}
        setDate={setDate}
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        user={user}
        showDrawer={showDrawer}
      />
      {displayMode === "funnel" && (
        <LeadColumns
          columns={columns}
          moveLead={moveLead}
          date={date}
          isLoading={isLoading}
          showDrawer={showDrawer}
          loadMoreLeads={loadMoreLeads}
          user={user}
        />
      )}
      {displayMode === "table" && (
        <LeadsTable 
          showDrawer={showDrawer}
        />
      )}
      <Drawer entity_type={LEAD_ENTITY}/>
    </Layout>
  );
};

export default Leads;
