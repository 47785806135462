import * as SETTINGS from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.settings]: (state, action) => {
        return action.payload;
    },

    [SETTINGS.LOAD_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const leadsLostReason = data.filter((item) => item.key === 'leads.lost_reason' && item.deleted_at === null);
        const leadsAcquisition = data.filter((item) => item.key === 'leads.acquisition' && item.deleted_at === null);
        const leadsCompanyType = data.filter((item) => item.key === 'leads.company_type' && item.deleted_at === null);
        const leadsTags = data.filter((item) => item.key === 'leads.tags' && item.deleted_at === null);
        const linkGroups = data.filter((item) => item.key === 'links.group_id' && item.deleted_at === null);
        const orgNames = data.filter((item) => item.key === 'customers.org_name' && item.deleted_at === null);
        const prospectingCadence = data.filter((item) => item.key.startsWith('prospecting.cadence') && item.deleted_at === null);
        const socialSellingCadence = data.filter((item) => item.key.startsWith('socialSelling.cadence') && item.deleted_at === null);

        return {
            'all': data,
            'leads.lost_reason': leadsLostReason ? leadsLostReason : [],
            'leads.acquisition': leadsAcquisition ? leadsAcquisition : [],
            'leads.company_type': leadsCompanyType ? leadsCompanyType : [],
            'leads.tags': leadsTags ? leadsTags : [],
            'links.group_id': linkGroups ? linkGroups : [],
            'customers.org_name': orgNames ? orgNames : [],
            'prospecting.cadence': prospectingCadence ? prospectingCadence : [],
            'socialSelling.cadence': socialSellingCadence ? socialSellingCadence : [],
            isLoading: false
        };
    },

    [SETTINGS.CREATE_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const { key } = data;

        return {
            ...state,
            [key]: [...state[key], data]
        };
    },

    [SETTINGS.UPDATE_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const { key, id } = data;

        return {
            ...state,
            [key]: state[key].map(item => item.id === id ? data : item)
        };
    },

    [SETTINGS.DELETE_SETTINGS.SUCCESS]: (state, action) => {
        const { id, key } = action.payload.data.data;

        return {
            ...state,
            [key]: state[key].filter(item => item.id !== id)
        };
    },

    [SETTINGS.MERGE_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload.data;
        const { key, deletedId } = data;

        return {
            ...state,
            [key]: [...state[key].filter((item) => item.id !== deletedId)]
        };
    }
};

const defaultState = {
    'leads.lost_reason': [],
    'leads.acquisition': [],
    'leads.company_type': [],
    'leads.tags': [],
    'links.group_id': [],
    'customers.org_name': [],
    'prospecting.cadence': [],
    'socialSelling.cadence': [],
    isLoading: true
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        SETTINGS.LOAD_SETTINGS.SUCCESS,
        SETTINGS.CREATE_SETTINGS.SUCCESS,
        SETTINGS.UPDATE_SETTINGS.SUCCESS,
        SETTINGS.DELETE_SETTINGS.SUCCESS
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.settings,
            data: newState
        });
    }

    return newState;
};

export default reducer;