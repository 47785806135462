import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { limitCharacterLength } from "~/utils/formatters";
import { LeadCalendarIcon } from "../LeadCalendarIcon";
import { Icon } from "antd";
import {
  CalendarIcon,
  Content,
  Date,
  DateContainer,
  LeadInfo,
  LeadName,
  MainContent,
  Container,
} from "./styles";

export function LeadCard({
  index,
  item,
  showDrawer,
}) {
  return (
    <Draggable key={index} draggableId={item.id} index={index}>
      {(providedProps, snapshotProps) => {
        const { innerRef, draggableProps, dragHandleProps } = providedProps;
        const { isDragging, isDraggingOver } = snapshotProps;

        return (
          <Container
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            isDragging={isDragging}
            isDraggingOver={isDraggingOver}
            onClick={() => showDrawer(item)}
          >
            <Content>
              <MainContent>
                <LeadInfo>
                  <DateContainer>
                    <CalendarIcon />
                    <Date>{moment(item.created_at).fromNow()}</Date>
                  </DateContainer>
                  <LeadName><Icon type="user"/> {limitCharacterLength(item.name, 33)}</LeadName>
                </LeadInfo>
                <LeadCalendarIcon item={item} />
              </MainContent>
            </Content>
          </Container>
        );
      }}
    </Draggable>
  );
}
