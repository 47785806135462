import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Row, Col, Modal } from 'antd';
import ActiveProspectingDrawer from './Drawer';
import * as settingActions from '~/containers/Settings/actions';

const { Column } = Table;

const ActiveProspecting = () => {
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [selectedCadence, setSelectedCadence] = useState(null);
    const dispatch = useDispatch();
    const allCadences = useSelector(state => state.settings['prospecting.cadence'] || []);

    const showDrawer = (cadence = null) => {
        setSelectedCadence(cadence);
        setIsDrawerVisible(true);
    };

    const handleCancel = () => {
        setIsDrawerVisible(false);
        setSelectedCadence(null);
    };

    const handleDelete = (record) => {
        Modal.confirm({
            title: "Você realmente deseja remover esta cadência?",
            content: "Esta ação não poderá ser desfeita.",
            okText: "Sim",
            okType: "danger",
            cancelText: "Cancelar",
            onOk: () => {
                dispatch(settingActions.deleteSetting({ id: record.id, key: 'prospecting.cadence' }));
            },
        });
    };

    return (
        <div>
            <Row type="flex" justify="end" style={{ marginBottom: 16 }}>
                <Col>
                    <Button type="primary" onClick={() => showDrawer()}>Adicionar</Button>
                </Col>
            </Row>
            <Table
                rowKey="id"
                dataSource={allCadences}
                bordered
            >
                <Column
                    title="Nome"
                    dataIndex="name"
                    key="name"
                    render={(_, record) => JSON.parse(record.value).name || ''}
                />
                <Column
                    key="actions"
                    width={80}
                    align="center"
                    render={(_, record) => (
                        <div style={{ display: "flex", gap: 6 }}>
                            <Button
                                type="primary"
                                icon="edit"
                                onClick={() => showDrawer(record)}
                            />
                            <Button
                                type="danger"
                                icon="delete"
                                onClick={() => handleDelete(record)}
                            />
                        </div>
                    )}
                />
            </Table>
            <ActiveProspectingDrawer
                visible={isDrawerVisible}
                onClose={handleCancel}
                selectedCadence={selectedCadence}
            />
        </div>
    );
};

export default ActiveProspecting;