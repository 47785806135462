import update from 'immutability-helper';
import get from 'lodash/get';
import { orderBy } from 'natural-orderby';
import * as ACTIONS from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import moment from 'moment';

const orderDescByColumnLog = (leads) => {
    const ordered = orderBy(leads, [(l) => {
        const updatedAt = get(l, 'updated_at');
        return updatedAt && moment(updatedAt).isValid() ? moment(updatedAt).valueOf() : null; 
    }], ['desc']);

    return ordered;
};

const defaultState = {
    isLoading: true,
    columns: [
        { id: 1, name:'Leads', style: { background: '#f5f5f5', color: '#722ed1', fontSize: '20px', textAlign: 'center' }, data: [] },
        { id: 2, name:'Follow Up',  style: { background: '#f5f5f5', color: '#1890ff', fontSize: '20px', textAlign: 'center'  }, data: []},
        { id: 3, name:'Proposta Assinada',  style: { background: '#f5f5f5', color: '#000', fontSize: '20px', textAlign: 'center'  }, data: []},
        { id: 4, name:'Em Processo', style: { background: '#f5f5f5', color: '#faad14', fontSize: '20px', textAlign: 'center'  }, data: []},
        { id: 5, name:'Concluído', style: { background: '#f5f5f5', color: '#52c41a', fontSize: '20px', textAlign: 'center'  }, data: [], hidden: true},
        { id: 6, name:'Cobrança', style: { background: '#f5f5f5', color: '#f5222d', fontSize: '20px', textAlign: 'center'  }, data: []},
    ],
    drawer: {
        data: {
            id: null,
        },
        show: false
    }
};

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.leadsV2]: (state, action) => {
        return action.payload;
    },

    [ACTIONS.SHOW]: (state, action) => {
        return update(state, {
            drawer: {
                data: { $set: get(action, 'payload.data') ? get(action, 'payload.data') : defaultState.drawer.data},
                show: { $set: true },
            }
        })
    },

    [ACTIONS.CLOSE]: (state) => {
        return update(state, {
            drawer: {
                data: { $set: defaultState.drawer.data} ,
                show: { $set: false },
            }
        })
    },

    [ACTIONS.LOAD.SUCCESS]: (state, action) => {
        const handledState = action.payload.data.data;
        return {
            ...state,
            columns: defaultState.columns.map((column) => {
                const currentColumn = handledState.filter((c) => c.column_id === column.id);
                return {
                    ...column,
                    data: orderDescByColumnLog(currentColumn),
                    total: currentColumn.length,
                }
            }),
            isLoading: false
        };
    },

    [ACTIONS.CREATE.SUCCESS]: (state, action) => {
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    const createdLead = get(action, 'payload.data.data'); 
                    if(column.id !== createdLead.column_id) return column;
                    return {
                        ...column,
                        data: orderDescByColumnLog([createdLead, ...column.data])
                    }
                })
            },
            drawer: {
                data: { $set: defaultState.drawer.data} ,
                show: { $set: false },
            }
        })
    },

    [ACTIONS.BATCH.SUCCESS]: (state, action) => {
        const leads = get(action, 'payload.data.data');
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    const columnLeads = leads.filter(l => l.column_id === column.id);
                    return {
                        ...column,
                        data: orderDescByColumnLog([...column.data, ...columnLeads])
                    }
                })
            },
            drawer: {
                data: { $set: defaultState.drawer.data} ,
                show: { $set: false },
            }
        })
    },

    [ACTIONS.SAVE.SUCCESS]: (state, action) => {
        const updatedLead = get(action, 'payload.data.data');
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if (column.id === updatedLead.column_id) {
                        return {
                            ...column,
                            data: orderDescByColumnLog([updatedLead, ...column.data.filter(l => l.id !== updatedLead.id)]),
                        }
                    } else if (column.data.some(l => l.id === updatedLead.id)) {
                        return {
                            ...column,
                            data: column.data.filter(l => l.id !== updatedLead.id),
                        }
                    } else {
                        return column;
                    }
                })
            },
            drawer: {
                data: { $set: defaultState.drawer.data} ,
                show: { $set: false },
            }
        })
    },

    [ACTIONS.DELETE.SUCCESS]: (state, action) => {
        const lead = get(action, 'meta.previousAction.payload.lead');
    
        return update(state, {
          columns: {
            $apply: (c) =>
              c.map((column) => {
                if (column.id === lead.column_id) {
                  return {  
                    ...column,
                    data: column.data.filter((l) => l.id !== lead.id),
                  };
                } else {
                  return column;
                }
              }),
          },
          drawer: {
                data: { $set: defaultState.drawer.data} ,
                show: { $set: false },
            }
        });
    },

    [ACTIONS.MOVE.INIT]: (state, action) => {

        const sourceClone = Array.from(action.props[0]);
        const destClone = Array.from(action.props[1]);
        const droppableSource = action.props[2];
        const droppableDestination = action.props[3];
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id !== parseInt(droppableSource.droppableId)
                        && column.id !==parseInt( droppableDestination.droppableId)) 
                        return column;

                    else if(column.id === parseInt(droppableSource.droppableId))
                        return {
                            ...column,
                            data: sourceClone
                        }
                    else if(column.id ===parseInt( droppableDestination.droppableId))
                    return {
                        ...column,
                        data: destClone
                    }
                
                })
            }
        })
    },

    [ACTIONS.MOVE.SUCCESS]: (state, action) => {
        const lead = get(action, 'payload.data.data');
        console.log('lead', lead);

        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id === lead.column_id){
                        return {
                            ...column,
                            data: orderDescByColumnLog(column.data.map(l => l.id === lead.id? lead : l))
                        }
                    }else{
                        return column;
                    }
                })
            }
        });
    },
}

const reducer = (state = defaultState, action) => {
    console.log('action', action);
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        ACTIONS.LOAD.SUCCESS,
        ACTIONS.MOVE.SUCCESS,
        ACTIONS.CREATE.SUCCESS,
        ACTIONS.BATCH.SUCCESS,
        ACTIONS.SAVE.SUCCESS,
        ACTIONS.DELETE.SUCCESS,
        ACTIONS.SHOW,
        ACTIONS.CLOSE
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.leadsV2,
            data: newState
        });
    }

    return newState;
};

export default reducer;