import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '~/components/UI';
import { ModuleHeader } from '~/components/ModuleHeader';
import { Container, Header, HeaderLeftContentWrapper, Main } from './styles';
import { Input, Button, message, Icon, Tooltip } from 'antd';
import { normalizeToFilter } from '~/utils/formatters';
import TableList from './components/table';
import DrawerSolicitation from './components/DrawerSolicitation';
import Tip from '~/components/Tip';
import { copyToClipBoard } from '~/utils/copy-to-clipboard';

export const Page = () => {

    const [filter, setFilter] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const data = useSelector(state => state.solicitations.data);

    const handleCopyLink = () => {
        const link = `${process.env.REACT_APP_CUSTOMER_DOMAIN}/cliente/solicitacoes`;
        copyToClipBoard(link);
        message.success('Link copiado para a área de transferência');
    };

  return (
    <div>
        <Layout>
            <Container>
                <Header>
                    <HeaderLeftContentWrapper>
                    <ModuleHeader 
                        breadcrumbs={['Solicitações']} 
                        title={<span>Solicitações <Tip>Este módulo é responsável por gerenciar as solicitações enviadas pelos clientes através do painel do cliente.</Tip></span>} 
                        actions={
                            <Tooltip title="Envie este link para que seus clientes sejam redirecionados diretamente para a tela de solicitação de NF-e após realizar o login">
                                <Button onClick={handleCopyLink} type="dashed">
                                    <Icon type="copy" /> Link direto
                                </Button>
                            </Tooltip>
                        }
                    />
                    <Input
                        placeholder="Digite para filtrar"
                        onChange={({ target }) =>
                        setFilter(normalizeToFilter(target.value))
                        }
                    />
                    </HeaderLeftContentWrapper>
                </Header>
                <Main>
                    <DrawerSolicitation 
                        data={selectedRow}
                        onClose={() => setSelectedRow(null)}
                    />
                    <TableList 
                        data={data}
                        filter={filter}
                        setSelectedRow={setSelectedRow}
                    />
                </Main>
            </Container>
        </Layout>
    </div>
  );
};

export default Page;
