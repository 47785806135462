export const ROUTES = {
  '/login': '/login',
  '/esqueci-a-senha': '/esqueci-a-senha',
  '/esqueci-a-senha/:token': '/esqueci-a-senha/:token',
  '/cadastro': '/cadastro',
  '/convidado/:invitation': '/convidado/:invitation',
  '/usuarios': '/usuarios',
  '/permissoes': '/permissoes',
  '/clientes': '/clientes',
  '/checklist': '/checklist',
  '/certificados': '/certificados',
  '/lembretes': '/lembretes',
  '/convencoes': '/convencoes',
  '/societario': '/societario',
  '/conta': '/conta',
  '/tarefas': '/tarefas',
  '/solicitacoes': '/solicitacoes',
  '/documentos': '/documentos',
  '/calendario/:id/:department': '/calendario/:id/:department',
  '/financeiro/extras': '/financeiro/extras',
  '/financeiro/mensalidades': '/financeiro/mensalidades',
  '/financeiro/comissoes': '/financeiro/comissoes',
  '/crm/leads': '/crm/leads',
  '/crm/social-selling': '/crm/social-selling',
  '/recuperacao-tributaria': '/recuperacao-tributaria',
  '/crm/prospeccao': '/crm/prospeccao',
  '/v2/leads/inss_obra': '/v2/leads/inss_obra',
  '/links': '/links',
  '/IRPF': '/IRPF',
  '/malha-fina/:department': '/malha-fina/:department',
  '/relatorios/inadimplentes': '/relatorios/inadimplentes',
  '/relatorios/prazos': '/relatorios/prazos',
  '/relatorios/honorarios': '/relatorios/honorarios',
  '/relatorios/emails': '/relatorios/emails',
  '/relatorios/emails/erros': '/relatorios/emails/erros',
  '/relatorios/kpis': '/relatorios/kpis',
  '/leads/forms/:id': '/leads/forms/:id',
  '/f/:token': '/f/:token',
  '/i/:id/:indicated_by_id?': '/i/:id/:indicated_by_id?',
  '/boards': '/boards',
  '/plugins': '/plugins',
  '/plugins/forms': '/plugins/forms',
  '/base-de-conhecimento': '/base-de-conhecimento',
  '/referral-program': '/indicacao',
  '/sair': '/sair',
  '/': '/'
}