import React from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import { table } from './tableColumns';

export const LeadsTable = ({
    showDrawer
}) => {
    const { 
        columns,
        isLoading,
    } = useSelector(state => state.leadsv2);

    const data = columns ? columns.reduce((acc, column) => {
        return acc.concat(column.data);
    }, []) : [];
    const mergedData = data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    const tableColumns = table.getColumns({columns, showDrawer});
    const columnsWidth = tableColumns.reduce((acc, column) => acc + column.width, 0);

    return (
        <div>
            <div className={`table-clabs`}>
                <Table 
                    loading={isLoading}
                    tableLayout="fixed"
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.customers
                    })}
                    bordered={true}
                    rowClassName={(record) => record.is_revised ? 'row-clabs row-green' : 'row-clabs'}
                    columns={tableColumns}
                    dataSource={mergedData}
                    rowKey="id"
                    scroll={{ x: columnsWidth, y: `${window.innerHeight-175}px`}}
                />
            </div>
        </div>
    );
}